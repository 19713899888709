import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { BlogArticlesQuery } from "../../graphql-types";
import AvatarSm from "./AvatarSm";
import css from "./BlogIndexPage.module.css";
import Layout from "./layouts/Layout";
import SEO from "./SEO";

const blogArticlesQuery = graphql`
  query BlogArticles {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      totalCount
      nodes {
        excerpt
        frontmatter {
          date
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default function BlogIndexPage() {
  const data = useStaticQuery<BlogArticlesQuery>(blogArticlesQuery);
  const articles = data.allMarkdownRemark.nodes;
  return (
    <Layout>
      <div className={css.articles}>
        <SEO
          title="Matt's Blog"
          description="Some dude with a fast motorcycle and a guitar. He writes code from time to time too."
        />
        <AvatarSm />
        <h1 className="mb-10">Matt's Blog</h1>
        <ol>
          {articles.map((a) => {
            if (!a.fields?.slug) return null;
            return (
              <li key={a.fields.slug} className={css.article}>
                <time>{a.frontmatter?.date}</time>
                <h2>
                  <Link to={a.fields.slug}>{a.frontmatter?.title}</Link>
                </h2>
                <p>
                  {a.excerpt} <Link to={a.fields.slug}>Read more</Link>
                </p>
              </li>
            );
          })}
        </ol>
      </div>
    </Layout>
  );
}
